import React from "react"

import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"
import img1 from "./../images/infoforesatte1.jpg"
import img2 from "./../images/infoforesatte2.jpg"
import styles from "./info-til-foresatte.module.scss"

class GuardiansPage extends React.Component {


    render() {

        return (
            <LayoutInfo>
                <SEO title="Informasjon til foresatte"

                    description="Informasjon om Leirskoledagboken til foresatte "
                />

                <div className="row">
                    <div className="col">
                        <h1 className="card-title">Leirskole - Ta vare på leirskolestemningen!</h1>
                        <div>
                            <img className={` ${styles.infoPicture} rounded float-left mr-3 mb-2`} src={img1} alt="Illustrasjonsfoto" />
                            <p>
                                Vi lager Leirskoledagbok i samarbeid med de fleste leirskoler i Norge. På forhånd tar vi kontakt med ditt barns skole for å gjøre avtale om å lage bok.
                            </p>

                            <p>
                                Det er viktig for oss at alle involverte parter er godt informert om opplegget sånn at boka kan bli best mulig. Vi vil formidle den unike «leirskolefølelsen»! Derfor gir vi elevene og lærerne deres mulighet til å bruke kamera som de vil, og oppfordrer til å ta bilder av alle aktiviteter og hendelser. Dette er jo den uka som mange trekker frem som den aller beste skoleuka i livet!
                            </p>

                            <p>
                                Etter leirskoleoppholdet får dere tilbud om å bestille bok til fritt gjennomsyn. Vi sender ut lenke for bestilling via e-post eller SMS alt ettersom hva vi har fått av kontaktopplysninger fra skolen.
                            </p>
                            <img className={` ${styles.infoPicture} rounded float-right  ml-3 mb-2`} src={img2} alt="Illustrasjonsfoto" />
                            <p>
                                Har du mottatt lenke på e-post eller sms er det bare å klikke lenken. Eller gå inn på  <a href="/">leirskolestemning.no</a> og tast inn koden bakerst i lenken.
                            </p>
                            <p>
                                Dersom du har barn som skal på eller har vært på leirskole, og ikke har fått tilbud om bok, send oss gjerne en forespørsel her <a href="mailto:kundeservice@leirskoledagboken.no">kundeservice@leirskoledagboken.no</a>.
                            </p>

                        </div>
                    </div>
                </div>
            </LayoutInfo>
        )
    }

}
export default GuardiansPage
